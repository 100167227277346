<template>
    <EntranceTemplate :type_id="type_id" :title="title" :type="type"></EntranceTemplate>
</template>

<script>
import EntranceTemplate from './index.vue'
export default {
    components:{EntranceTemplate},
    data:() => ({
        type_id:3,
        type:'ENTRANCE_CARD',
        title:"Entrance Card Template"
    })
}
</script>

